<template>
  <div>
    <el-form ref="form" label-position="left" :model="form" label-width="100px">
      <el-form-item label="商品分类">
        <el-select v-model="form.region" placeholder="请选择活动区域">
          <el-option label="区域一" value="shanghai"></el-option>
          <el-option label="区域二" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="经营户">
        <el-select v-model="form.region" placeholder="请选择活动区域">
          <el-option label="区域一" value="shanghai"></el-option>
          <el-option label="区域二" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择商品">
        <el-select v-model="form.region" placeholder="请选择活动区域">
          <el-option label="区域一" value="shanghai"></el-option>
          <el-option label="区域二" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品别名">
        <el-input style="width:217px" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="商品计量单位">
        <el-select v-model="form.region" placeholder="请选择活动区域">
          <el-option label="区域一" value="shanghai"></el-option>
          <el-option label="区域二" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品数量">
        <el-input style="width:217px" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="销售价格">
        <el-input style="width:217px" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item class="picture" label="商品照片">
        <el-upload
          action="https://jsonplaceholder.typicode.com/posts/"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </el-form-item>
      <el-form-item class="photos" label="电子秤商品序号(plu)">
        <el-input style="width:217px" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="进货价格">
        <el-input style="width:217px" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="供应商">
        <el-select v-model="form.region" placeholder="请选择活动区域">
          <el-option label="区域一" value="shanghai"></el-option>
          <el-option label="区域二" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品产地">
        <el-select v-model="form.region" placeholder="请选择活动区域">
          <el-option label="区域一" value="shanghai"></el-option>
          <el-option label="区域二" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="picture" label="进货凭证">
        <el-upload
          action="https://jsonplaceholder.typicode.com/posts/"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </el-form-item>
      <el-form-item class="picture" label="商品描述">
        <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="form.textarea2"
          style="width:400px"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="商品状态">
        <el-radio v-model="form.radio" label="1">上架</el-radio>
        <el-radio v-model="form.radio" label="2">上架</el-radio>
      </el-form-item>
      <el-button type="primary">提交</el-button>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'purchaseList',
  data () {
    return {
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        textarea2: '',
        radio: '1'
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .photos .el-form-item__label {
  line-height: 14px;
}
/deep/ .el-form-item {
  display: flex;
  align-items: center;
}
/deep/ .picture {
  align-items: flex-start;
}
</style>
